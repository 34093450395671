$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_ie-mixins';

:local {
    .row {
        display: flex;
        flex-wrap: wrap;
        margin-left: -$sassy-spacing-x-small;
        margin-right: -$sassy-spacing-x-small;

        @include sassy-ie-specific() {
            margin-left: 0;
            margin-right: 0;
            justify-content: space-between;
        }
    }

    // Align row items
    .top {
        align-items: flex-start;
    }
    .bottom {
        align-items: flex-end;
    }
    .vCenter {
        align-items: center;
    }
    .left {
        justify-content: flex-start;
    }
    .right {
        justify-content: flex-end;
    }
    .hCenter {
        justify-content: center;
    }
}
