@use 'sass:math';

@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_ie-mixins';
@import './_strCamelCase';

@mixin __INTERNAL_USE_ONLY_COL_WIDTH__($size, $columns) {
    flex: 0 0 percentage(math.div($size, $columns));
}

@mixin __INTERNAL_USE_ONLY_COL_SIZE_CLASS__($device, $size) {
    $class-name: __INTERNAL_USE_ONLY_STR_CAMEL_CASE__(col-#{$device}-#{$size});
    .#{$class-name} {
        @content;
    }
}

@mixin __INTERNAL_USE_ONLY_COL_OFFSET__($device, $size, $columns) {
    $offset-class-name: __INTERNAL_USE_ONLY_STR_CAMEL_CASE__(col-#{$device}-offset-#{$size});
    $pull-class-name: __INTERNAL_USE_ONLY_STR_CAMEL_CASE__(col-#{$device}-pull-#{$size});
    $push-class-name: __INTERNAL_USE_ONLY_STR_CAMEL_CASE__(col-#{$device}-push-#{$size});
    .#{$offset-class-name} {
        margin-left: percentage(math.div($size, $columns));
    }
    .#{$pull-class-name} {
        right: if($size > 0, percentage(math.div($size, $columns)), auto);
    }
    .#{$push-class-name} {
        left: if($size > 0, percentage(math.div($size, $columns)), auto);
    }
}

@mixin __INTERNAL_USE_ONLY_COL_FLEX_ORDER__($device) {
    $first: __INTERNAL_USE_ONLY_STR_CAMEL_CASE__(col-#{$device}-first);
    $last: __INTERNAL_USE_ONLY_STR_CAMEL_CASE__(col-#{$device}-last);
    .#{$first} {
        order: -1;
    }
    .#{$last} {
        order: 1;
    }
}

@mixin __INTERNAL_USE_ONLY_COL_ALIGN_SELF__($device) {
    $align-top: __INTERNAL_USE_ONLY_STR_CAMEL_CASE__(col-#{$device}-top);
    $align-center: __INTERNAL_USE_ONLY_STR_CAMEL_CASE__(col-#{$device}-center);
    $align-bottom: __INTERNAL_USE_ONLY_STR_CAMEL_CASE__(col-#{$device}-bottom);
    $align-stretch: __INTERNAL_USE_ONLY_STR_CAMEL_CASE__(col-#{$device}-stretch);
    .#{$align-top} {
        align-self: flex-start;
    }
    .#{$align-center} {
        align-self: center;
    }
    .#{$align-bottom} {
        align-self: flex-end;
    }
    .#{$align-stretch} {
        align-self: stretch;
    }
}

@mixin __INTERNAL_USE_ONLY_GENERATE_COLUMN_CLASSES_FOR_DEVICE__($columns, $device) {
    @for $i from 1 through $columns {
        @include __INTERNAL_USE_ONLY_COL_SIZE_CLASS__($device, $i) {
            @include __INTERNAL_USE_ONLY_COL_WIDTH__($i, $columns);
        }
        @include sassy-ie-specific() {
            @include __INTERNAL_USE_ONLY_COL_SIZE_CLASS__($device, $i) {
                flex: 0 0 auto;
                width: percentage(math.div($i, $columns));
            }
        }
        @include __INTERNAL_USE_ONLY_COL_OFFSET__($device, $i, $columns);
    }
    @include __INTERNAL_USE_ONLY_COL_FLEX_ORDER__($device);
    @include __INTERNAL_USE_ONLY_COL_ALIGN_SELF__($device);
}

@mixin __INTERNAL_USE_ONLY_GENERATE_GRID_COLUMN_CLASSES__($columns, $sizes) {
    @each $size in $sizes {
        $device: mobile;
        @if $size == 'mobile' {
            $device: mobile;
        } @else if $size == 'tablet-portrait' {
            $device: tablet-portrait;
        } @else if $size == 'tablet-landscape' {
            $device: tablet-landscape;
        } @else if $size == 'desktop' {
            $device: desktop;
        }
        // Set classes for media device
        @if $device == mobile {
            @include __INTERNAL_USE_ONLY_GENERATE_COLUMN_CLASSES_FOR_DEVICE__($columns, $size);
        } @else {
            @include sassy-breakpoint-above($device) {
                @include __INTERNAL_USE_ONLY_GENERATE_COLUMN_CLASSES_FOR_DEVICE__($columns, $size);
            }
        }
    }
}
